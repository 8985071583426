import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './slices/profileSlices';
import spinnerReducer from './slices/spinnerSlices';
import accountsReducer from './slices/accountsSlice';
import notificationReducer from './slices/notificationSlice';
import alertReducer from './slices/alertSlice';

export const store = configureStore({
  reducer: {
    user: profileReducer,
    spinner: spinnerReducer,
    accounts: accountsReducer,
    notification: notificationReducer,
    alert: alertReducer
  }
})

export type AppStore = typeof store;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
