import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { INotification } from "../../interfaces/utilsInterface";

const initialState: INotification = {
    type: 'success',
    isVisible: false,
    title: '',
    message: '',
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotification: (state, action: PayloadAction<INotification>) => {
            state.isVisible = action.payload.isVisible;
            state.title = action.payload.title;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },
    }
});

export const { setNotification } = notificationSlice.actions;
export const notification = (state: RootState) => state.notification;
export default notificationSlice.reducer;
