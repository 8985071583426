import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { IUserProfile } from "../../interfaces/userProfileInterface";
import { ICustomer } from "../../interfaces/customerInterface";
import {
  SESSION_CUSTOMER_KEY,
  SESSION_PROFILE_KEY,
  SESSION_TOKEN_KEY,
} from "../../utils/constants";

const address = {
  street: "",
  num_ext: "",
  num_int: "",
  reference: "",
  neighborhood: "",
  district: "",
  estate: "",
  cp: "",
};

const initialState = {
  current_customer_id: '',
  profile: {
    customer_type: 0,
    id: 0,
    profile: "",
    profile_id: 0,
    role: "",
    role_id: 0,
    username: "",
    customer_id: "",
    token: "",
  },
  information: {
    rfc: "",
    parent_id: "",
    account_customer_id: "",
    level: 0,
    company_name: "",
    name: "",
    ap_paterno: "",
    ap_materno: "",
    taxpayer_type_id: 0,
    address: address,
    company_address: address,
    contact_name: "",
    contact_email: "",
    contact_tel: "",
    creation_date: "",
  },
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<IUserProfile>) => {
      state.profile = action.payload;
      const { token, customer_id, ...profile } = action.payload;
      if (token) sessionStorage.setItem(SESSION_TOKEN_KEY, token);
      if (customer_id)
        sessionStorage.setItem(SESSION_CUSTOMER_KEY, customer_id);
      if (profile)
        sessionStorage.setItem(SESSION_PROFILE_KEY, JSON.stringify(profile));
    },
    cleanSession: (state) => {
      state.profile = initialState.profile;
      state.information = initialState.information;
      sessionStorage.removeItem(SESSION_TOKEN_KEY);
      sessionStorage.removeItem(SESSION_CUSTOMER_KEY);
      sessionStorage.removeItem(SESSION_PROFILE_KEY);
    },
    setCustomerInfo: (state, action: PayloadAction<ICustomer | null>) => {
      if (action.payload) {
        state.information.rfc = action.payload.rfc;
        state.information.parent_id = action.payload.parent_id
          ? action.payload.parent_id
          : "";
        state.information.account_customer_id = action.payload
          .account_customer_id
          ? action.payload.account_customer_id
          : "";
        state.information.level = action.payload.level
          ? action.payload.level
          : 0;
        state.information.company_name = action.payload.company_name
          ? action.payload.company_name
          : "";
        state.information.name = action.payload.name ? action.payload.name : "";
        state.information.ap_paterno = action.payload.ap_paterno
          ? action.payload.ap_paterno
          : "";
        state.information.ap_materno = action.payload.ap_materno
          ? action.payload.ap_materno
          : "";
        state.information.taxpayer_type_id = action.payload.taxpayer_type_id;
        state.information.address = action.payload.address
          ? action.payload.address
          : state.information.address;
        state.information.contact_name = action.payload.contact_name;
        state.information.contact_email = action.payload.contact_email;
        state.information.contact_tel = action.payload.contact_tel;
        state.information.creation_date = action.payload.creation_date;
      }
    },
    setPartnerInfo: (state, action: PayloadAction<ICustomer | null>) => {
      if (action.payload) {
        state.information.rfc = action.payload.rfc;
        state.information.company_name = action.payload.company_name
          ? action.payload.company_name
          : "";
        state.information.taxpayer_type_id = action.payload.taxpayer_type_id;
        state.information.company_address = action.payload.company_address
          ? action.payload.company_address
          : state.information.company_address;
        state.information.contact_name = action.payload.contact_name;
        state.information.contact_name = action.payload.contact_name;
        state.information.contact_email = action.payload.contact_email;
        state.information.contact_tel = action.payload.contact_tel;
        state.information.creation_date = action.payload.creation_date;
      }
    },
    setCurrentCustomerId: (state, action: PayloadAction<string>) =>{
      state.current_customer_id = action.payload
    }
  },
});

export const { setProfile, setPartnerInfo, setCustomerInfo, cleanSession, setCurrentCustomerId } =
  profileSlice.actions;
export const user = (state: RootState) => state.user;
export default profileSlice.reducer;
