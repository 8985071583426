import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { ISpinner } from '../../interfaces/utilsInterface';

const initialState = {
    loading: false,
    text: '',
};

export const spinnerSlice = createSlice({
    name: 'spinner',
    initialState,
    reducers: {
        loadSpinner: (state, action: PayloadAction<ISpinner>) => {
            state.loading = action.payload.loading;
            state.text = action.payload.text? action.payload.text : '';
        },
    },
});

export const { loadSpinner } = spinnerSlice.actions;
export const spinner = (state: RootState) => state.spinner;
export default spinnerSlice.reducer;
