import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IAccountsInfo } from '../../interfaces/accountsInterface';

const initialState: IAccountsInfo = {
    id: '',
    type: 0,
    amount: 0,
    currency: '',
    linked_cellphone: '',
    creation_date: '',
    clabes: [{
        account_id: '',
        clabe: '',
        id: 0,
        payment_provider_id: 0,
    }],
};

export const accountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {
        setCurrentAccount: (state, action: PayloadAction<IAccountsInfo>) => {
            state.id = action.payload.id;
            state.type = action.payload.type;
            state.amount = action.payload.amount;
            state.currency = action.payload.currency;
            state.linked_cellphone = action.payload.linked_cellphone;
            state.creation_date = action.payload.creation_date;
            state.clabes = [...action.payload.clabes];
        },
    }
});

export const { setCurrentAccount } = accountsSlice.actions;
export const accounts = (state: RootState) => state.accounts;
export default accountsSlice.reducer;
