import { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from './redux/store';
import { PATHS } from './utils/constants';
import Spinner from './components/Spinner';
import Notification from './components/Notification';
import './styles/fonts.scss';
import './App.scss';
import Alert from './components/Alert';

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ './components/NotFound'));
const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ './pages/Login'));
const RecoverPasswordPage = lazy(() => import(/* webpackChunkName: "RecoverPasswordPage" */ './pages/Login/RecoverPasswordPage'));
const Accounts = lazy(() => import(/* webpackChunkName: "Accounts" */ './pages/Accounts'));
const AccountDetails = lazy(() => import(/* webpackChunkName: "AccountDetails" */ './pages/Accounts/AccountDetails'));
const Movements = lazy(() => import(/* webpackChunkName: "Movements" */ './pages/Movements'));
const Transfers = lazy(() => import(/* webpackChunkName: "Transfers" */ './pages/Transfers'));
const eBankingPage = lazy(() => import(/* webpackChunkName: "eBankingPage" */ './pages/eBanking'));
const CustomerRegistration = lazy(() => import(/* webpackChunkName: "eBankingPage" */ './pages/CustomerRegistration'));
const LegalEntity = lazy(() => import(/* webpackChunkName: "eBankingPage" */ './pages/CustomerRegistration/LegalEntity'));
const NaturalPerson = lazy(() => import(/* webpackChunkName: "eBankingPage" */ './pages/CustomerRegistration/NaturalPerson'));

const App = () => {
    return (
        <div className='App'>
            <Suspense fallback={<h1>Cargando...</h1>}>
                <Provider store={store}>
                    <BrowserRouter>
                        <Routes>
                            <Route path={PATHS.login} Component={LoginPage} />
                            <Route path={PATHS.recoverPassword} Component={RecoverPasswordPage} />
                            <Route path={PATHS.eBanking} Component={eBankingPage}>
                                {/* Mis cuentas */}
                                <Route path={PATHS.accounts} Component={Accounts} />
                                <Route path={PATHS.accountDetails} Component={AccountDetails} >
                                    <Route path={PATHS.movements} Component={Movements} />
                                    <Route path={PATHS.transfers} Component={Transfers} />
                                </Route>
                                {/* Altas de clientes */}
                                <Route path={PATHS.customerRegistration} Component={CustomerRegistration} />
                                <Route path={PATHS.legalEntity} Component={LegalEntity} />
                                <Route path={PATHS.naturalPerson} Component={NaturalPerson} />
                            </Route>
                            <Route path='*' Component={NotFound} />
                        </Routes>
                    </BrowserRouter>
                    <Spinner />
                    <Notification />
                    <Alert />
                </Provider>
            </Suspense>
        </div>
    );
}

export default App;
