import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import LinearProgress from '@mui/material/LinearProgress';
import './Spinner.scss';

/**
 * Componente que bloquea la pantalla para indicar que se esta cargando la información
 */
const Spinner = () => {

    /**
     * Para mostrar el componente es necesario de actualizar el estado del spinner
     */
    const spinner = useSelector((state: RootState) => state.spinner);

    return (<>
        {spinner.loading &&
            <div className='spinner-container w-full'>
                <h1>Estamos procesando la información</h1>
                <div className='linear-progress'>
                    <LinearProgress color="primary" />
                </div>
                {spinner.text && <p>{spinner.text}</p>}
                {!spinner.text && <p>Esto puede tardar un poco...</p>}
            </div>
        }
    </>);
};

export default Spinner;
