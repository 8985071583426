import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import AlertMui from '@mui/material/Alert';
import { useEffect } from 'react';
import { setAlert } from '../../redux/slices/alertSlice';

const Alert = () => {
    const alert = useSelector((state: RootState) => state.alert);
    const dispatch = useDispatch()
    useEffect(()=>{
        if(alert.time && alert.time > 0){
            console.log('setting close')
            setTimeout(()=>{
                dispatch(setAlert({show: false, time: 0, severity: 'error'}))
            }, alert.time)
        }
    },[alert])
    return (<div className='absolute w-full top-12 px-16'>
        {alert.show &&
            <AlertMui severity={alert.severity || 'error'}>{alert.text}</AlertMui>
        }
    </div>);
};

export default Alert;
